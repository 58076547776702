
import './App.css'
import Form from './components/Form'
// import RegistrationForm from './components/RegistrationForm'
// import MedicalForm from './components/MedicalForm'
// import DropDownTest from './components/FormComponents/DropDownTest'
function App() {
  return (
    <>
     {/* <MedicalForm /> */}
     {/* <RegistrationForm /> */}
     {/* <DropDownTest/> */}
     <Form />
    </>
  )
}

export default App
